<template>
	<main class="page-container">
		<div class="page-header-banner">
			<picture>
				<source media="(max-width: 800px)" srcset="@/assets/img/@tmp/banner_sample_mo.jpg" />
				<source media="(min-width: 801px)" srcset="@/assets/img/@tmp/banner_sample_pc.jpg" />
				<img src="@/assets/img/@tmp/banner_sample_pc.jpg" alt="" />
			</picture>
		</div>
		<div class="page-body">
			<!-- Tab list -->
			<nav class="page-nav style-orange">
				<div class="container">
					<ul class="nav-list">
						<li class="nav-item is-active">
							<router-link to="/information/home">
								<span class="nav-text">소상공인 뉴스 홈</span>
							</router-link>
						</li>
						<li v-for="(item, idx) in ctgrItems" class="nav-item" :key="idx" :class="{ 'is-active': item.id === plygrndCtgrDcd }">
							<router-link to="/information/list" class="nav-link">
								<span class="nav-text">{{ item.text }}</span>
							</router-link>
						</li>
					</ul>
				</div>
			</nav>
			<!-- //Tab list -->

			<div class="container">
				<section class="home-section" v-for="(item, idx) in ctgrItems" :key="idx">
					<div class="home-section-header">
						<div class="header-column">
							<h3 class="home-section-title">{{ item.text }}</h3>
						</div>
						<div class="header-column">
							<router-link to="/information/list" class="link-more-icon">
								<span class="text">더보기</span>
							</router-link>
						</div>
					</div>
					<div class="card-list-wrapper">
						<!-- <div class="list-header">
							<template>
								<div class="header-column"></div>
								<div class="header-column">
									<ul class="sort-list">
										<li class="sort-item is-active"><button type="button" @click.prevent="selectSort('')">최신순</button></li>
										<li class="sort-item"><button type="button" @click.prevent="selectSort('I')">인기순</button></li>
									</ul>
								</div>
							</template>
						</div> -->
						<common-list :div-class="['list-body']" :is-loading="isLoading" :is-no-result="isNoResult">
							<card-list :items="items" :showTitle="true" />
						</common-list>
					</div>
				</section>
			</div>
		</div>
	</main>
</template>

<script>
import { ACT_GET_ISSUE_LIST } from '@/store/_act_consts';
import { getItems, lengthCheck, setPaging, tryResCallback } from '@/assets/js/utils';
import CardList from '@/components/information/CardList';
import NoResult from '@/components/common/NoResult';
import CommonList from '@/components/common/CommonList';
import { mapGetters } from 'vuex';
import menus from '@/assets/js/menus';

export default {
	name: 'InfoHome',
	components: {
		NoResult,
		CommonList,
		CardList,
	},
	data: () => ({
		hasMore: false,
		totalCount: 0,
		pageSize: 12,
		pageNo: 1,
		sortCd: '',
		isNoResult: false,
		isLoading: false,
		plygrndCtgrDcd: 1,
		ctgrItems: [
			{
				id: '1',
				text: '유통ㆍ마케팅 트렌드, 노무ㆍ세무',
			},
			{
				id: '2',
				text: '정책동향',
			},
			{
				id: '3',
				text: '뉴스레터',
			},
			{
				id: '4',
				text: '기타 콘텐츠',
			},
		],
		items: [
			{ issueId: 59, issueTit: '콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. ', issueSmlTit: '22-26', issueCn: null, issueNo: '22-26', rprsImgPtUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/issue/rprs/20465/2023/09/06/e2250810.jpg', rprsImgFileNm: null, thmbnlImgPtUrl: null, thmbnlImgFileNm: null, atchFilePtUrl: null, atchFileNm: null, inqCnt: 0, expsrYn: 'Y', mdfcnDt: 1693990539137, preId: 0, nextId: 0 },
			{ issueId: 58, issueTit: '콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다.', issueSmlTit: '22-25', issueCn: null, issueNo: '22-25', rprsImgPtUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/issue/rprs/20465/2023/09/06/235a9f8d.jpg', rprsImgFileNm: null, thmbnlImgPtUrl: null, thmbnlImgFileNm: null, atchFilePtUrl: null, atchFileNm: null, inqCnt: 0, expsrYn: 'Y', mdfcnDt: 1693990550957, preId: 0, nextId: 0 },
			{ issueId: 57, issueTit: '콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다.', issueSmlTit: '22-24', issueCn: null, issueNo: '22-24', rprsImgPtUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/issue/rprs/20465/2023/09/06/cc5b4abd.jpg', rprsImgFileNm: null, thmbnlImgPtUrl: null, thmbnlImgFileNm: null, atchFilePtUrl: null, atchFileNm: null, inqCnt: 0, expsrYn: 'Y', mdfcnDt: 1693990568737, preId: 0, nextId: 0 },
			{ issueId: 56, issueTit: '콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다.', issueSmlTit: '22-23', issueCn: null, issueNo: '22-23', rprsImgPtUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/issue/rprs/20465/2023/09/06/609847d2.jpg', rprsImgFileNm: null, thmbnlImgPtUrl: null, thmbnlImgFileNm: null, atchFilePtUrl: null, atchFileNm: null, inqCnt: 0, expsrYn: 'Y', mdfcnDt: 1693990617753, preId: 0, nextId: 0 },
		],

		tryCount: 0,
		menus: menus.filter((x) => x.code === 'information'), //모바일 중메뉴 데이터 (20220714 - hib)
	}),
	computed: {
		...mapGetters('common', ['isMobile']),
	},
	created() {
		this.getIssueList();
	},
	methods: {
		getIssueList(isInit) {
			if (isInit) {
				// this.items = [];
				this.pageNo = 1;
			}
			this.$store
				.dispatch(`information/${ACT_GET_ISSUE_LIST}`, {
					pageNo: this.pageNo,
					pageSize: this.pageSize,
					sortCd: this.sortCd,
				})
				.then((res) => {
					tryResCallback(this.tryCount, res, this.getIssueList, this.setIssue);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		setIssue(res) {
			if (lengthCheck(res)) {
				// this.items = this.items.concat(getItems(res));
			}
			setPaging(this, res);
		},
		morePage() {
			if (this.hasMore) this.pageNo++;
			this.getIssueList();
		},
		selectSort(el) {
			if (el === 'I') {
				this.sortCd = 'I';
			} else {
				this.sortCd = '';
			}
			this.getIssueList(true);
		},
		/* 중메뉴이동 (20220714 - hib) */
		click() {
			let element = document.getElementById('select_list');
			element.classList.toggle('show_list');
			let element02 = document.getElementById('select_btn');
			element02.classList.toggle('active');
		},
	},
};
</script>
